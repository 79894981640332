/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
const {galleryGlobalsStrategy} = require('./gallery/galleryGlobalStrategy');
const {GalleryApp} = require('./gallery/components/GalleryApp/GalleryApp');

const {
  withSentryErrorBoundary,
} = require('@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary');
const {GALLERY_PAGE_DSN} = require('@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf');
const {getWrappedComponent} = require('./appConfig');
const {withStyles} = require('@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles');

const App = getWrappedComponent({
  entryName: 'gallery',
  Component: GalleryApp,
  globalPropsStrategy: galleryGlobalsStrategy,
  withStyleStrategy: withStyles,
});

module.exports = {
  default: {
    component: withSentryErrorBoundary(App, {
      dsn: GALLERY_PAGE_DSN,
      config: {environment: 'Native Component'},
    }),
  },
};
