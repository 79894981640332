import React from 'react';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa/Dropdown';
import {classes, st} from './Sort.st.css';
import s from './Sort.scss';
import {sortOptions} from '../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export enum DataHook {
  SelectedIOptionIcon = 'selected-option-icon',
  SortDropdown = 'sort-dropdown',
}

class SortComp extends React.Component<IGalleryGlobalProps & IProvidedTranslationProps> {
  private readonly getOptions = () => {
    const {sortingOptions} = this.props.globals;

    return sortingOptions.map((o) => ({
      isDisabled: false,
      isSelectable: true,
      id: o.id,
      value: this.getOptionText(o),
    }));
  };

  private readonly getOptionText = (o) => {
    return this.props.t(o.titleKey);
  };

  private readonly onSelect = (option: DropdownOptionProps) => {
    const sortingOption = sortOptions.find((o) => o.id === option.id);
    this.props.globals.sortProducts(sortingOption);
  };

  private readonly onExpandedChange = (isExpanded) => {
    if (!isExpanded) {
      return;
    }

    this.props.globals.sendSortClickBiEvent();
  };

  public render = () => {
    const {selectedSort} = this.props.globals;

    return (
      <Dropdown
        onExpandedChange={this.onExpandedChange}
        data-hook={DataHook.SortDropdown}
        className={st(classes.sort, s.selector)}
        initialSelectedId={selectedSort.id}
        onChange={this.onSelect}
        placement={'bottom'}
        options={this.getOptions()}
      />
    );
  };
}

export const Sort = withGlobals(withTranslations()(SortComp));
